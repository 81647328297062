import React, { useCallback, useEffect, useId, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Button,
  Card,
  dialog,
  Flex,
  Icon,
  Loader,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import { handleErrors } from "@api/handle-errors";
import { parseRefinementIdFromUrl } from "@src/shared/utils/parse-refinement-id-from-url";
import { useUserInfo } from "@store/user";

import {
  useDeleteLienWaiverRequestMutation,
  useRenderBillLienWaiverPdfMutation,
} from "../api/api";
import {
  LIEN_WAIVER_LINKED_STATUS,
  LIEN_WAIVER_REQUESTED_STATUS,
  LIEN_WAIVER_SIGNED_STATUS,
  LIEN_WAIVER_STATUS,
  PDF_BASE64_TYPE,
  STRINGS,
  TEMPLATE_NOT_REQUESTED_ID,
} from "../constants/constants";
import type { BillLienWaiver, LienWaiverVendor } from "../types";

import { BillLienWaiverFieldCombobox } from "./bill-lien-waiver-field-combobox";
import { BillLienWaiverFieldRequested } from "./bill-lien-waiver-field-requested";
import { BillLienWaiverFieldSigned } from "./bill-lien-waiver-field-signed";
import { BillLienWaiverMarkSignedDialog } from "./bill-lien-waiver-mark-signed-dialog";
import { BillLienWaiverPreviewDrawer } from "./bill-lien-waiver-preview-drawer";
import { BillLienWaiverRequestDialog } from "./bill-lien-waiver-request-dialog";

export type BillLienWaiverFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
  recipientEmail?: string;
  vendor: LienWaiverVendor;
  paymentAmount?: number;
  billId?: string | number;
  billPaymentId?: string | number;
  billLienWaiver?: BillLienWaiver;
  onRequestUpdate?: () => void;
  billInternalVersion?: string;
};

export const BillLienWaiverField = ({
  value,
  onChange,
  recipientEmail,
  vendor,
  paymentAmount,
  billId,
  billPaymentId,
  billLienWaiver,
  onRequestUpdate,
  billInternalVersion,
}: BillLienWaiverFieldProps) => {
  const previewDrawer = useDialog({ lazy: true });
  const requestDialog = useDialog({ lazy: true });
  const uploadSignatureDialog = useDialog({ lazy: true });
  const [deleteLienWaiverRequest, { isLoading }] =
    useDeleteLienWaiverRequestMutation();
  const formId = useId();
  const [params] = useSearchParams();
  const lienWaiverId = params.get("lien-waiver-id");
  const lienWaiverFieldRef = useRef<HTMLDivElement>(null);
  const [
    renderLienWaiverPDF,
    { data: templateData, isLoading: isLoadingLienWaiverPDF },
  ] = useRenderBillLienWaiverPdfMutation({
    fixedCacheKey: billInternalVersion,
  });

  const isValidValue = !!value && value !== TEMPLATE_NOT_REQUESTED_ID;
  const { canManageLienWaiverRequest } = useUserInfo();

  const billLienWaiverStatus =
    !billLienWaiver ||
    !LIEN_WAIVER_LINKED_STATUS.includes(billLienWaiver.status)
      ? undefined
      : billLienWaiver.status;

  const isUnrequested = !!billId && !billLienWaiverStatus;
  const isLinkedStatus = !!billLienWaiverStatus && !!billLienWaiver;
  const isRequested =
    isLinkedStatus &&
    LIEN_WAIVER_REQUESTED_STATUS.includes(billLienWaiverStatus);
  const isSigned =
    isLinkedStatus && LIEN_WAIVER_SIGNED_STATUS.includes(billLienWaiverStatus);

  const haveMissingFields = useMemo(
    () => isLoadingLienWaiverPDF || !!templateData?.missingFields,
    [templateData?.missingFields, isLoadingLienWaiverPDF]
  );

  const previewPDF = isRequested
    ? billLienWaiver?.fileExport.document
    : billLienWaiver?.pdf;

  const onDelete = useEvent(() => {
    if (!billLienWaiver?.id) return;

    dialog.confirmation({
      title: STRINGS.DELETE_LIEN_WAIVER_REQUEST_DIALOG_TITLE,
      message: STRINGS.DELETE_LIEN_WAIVER_REQUEST_DIALOG_TEXT,
      action: {
        primary: {
          children: STRINGS.DELETE,
          color: "error",
          onClick: async () => {
            try {
              await deleteLienWaiverRequest({ id: billLienWaiver?.id });
              toast.success(STRINGS.LIEN_WAIVER_REQUEST_DELETED);
              onRequestUpdate?.();
            } catch (error) {
              handleErrors(error);
            }
          },
        },
      },
    });
  });

  const loadLienWaiverTemplateData = useCallback(
    (lienWaiverTemplate: string) => {
      if (!billId) return;
      renderLienWaiverPDF({
        billId: billId!,
        lienWaiverTemplateId: parseRefinementIdFromUrl(lienWaiverTemplate)!,
        paymentAmount: paymentAmount,
        billPaymentId: billPaymentId!,
      });
    },
    [billId, paymentAmount, billPaymentId, renderLienWaiverPDF]
  );

  const onDownloadRenderedPdf = useEvent(async () => {
    if (!billId || !value || !templateData) return;

    const a = document.createElement("a");
    a.href = `${PDF_BASE64_TYPE}${templateData.pdf}`;
    a.download = `lien-waiver-bill-${billId}__${new Date().toISOString()}.pdf`;
    a.click();
    a.remove();
  });

  useEffect(() => {
    if (isValidValue && isUnrequested && canManageLienWaiverRequest) {
      loadLienWaiverTemplateData(value);
    }
  }, [
    isValidValue,
    isUnrequested,
    loadLienWaiverTemplateData,
    value,
    canManageLienWaiverRequest,
  ]);

  const shouldScroll = !!lienWaiverId && lienWaiverId === billLienWaiver?.id;

  useEffect(() => {
    if (shouldScroll) {
      lienWaiverFieldRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [shouldScroll]);

  return (
    <Flex
      width="full"
      direction="column"
      id={billLienWaiver ? billLienWaiver.id : formId}
      ref={lienWaiverFieldRef}
    >
      <Text size="sm">{STRINGS.LIEN_WAIVER}</Text>
      <Card as={Flex} width="full" size="sm" justify="space-between" gap="lg">
        {!billId ? (
          <Flex width="full" height="48px" justify="center" align="center">
            <Loader size="xl" />
          </Flex>
        ) : (
          <Flex direction="column" gap="md" width="full">
            <Flex width="full" gap="md">
              {isUnrequested && (
                <BillLienWaiverFieldCombobox
                  value={value}
                  onChange={onChange}
                  paymentAmount={paymentAmount}
                  openRequestDialog={requestDialog.show}
                  onRequestUpdate={onRequestUpdate}
                  billLienWaiver={billLienWaiver}
                  billPaymentId={billPaymentId}
                  billId={billId}
                  haveMissingFields={haveMissingFields}
                  vendor={vendor}
                />
              )}

              {isRequested && onRequestUpdate && (
                <BillLienWaiverFieldRequested
                  billLienWaiver={billLienWaiver}
                  onRequestUpdate={onRequestUpdate}
                />
              )}
              {isSigned && (
                <BillLienWaiverFieldSigned
                  markedAsSigned={
                    billLienWaiver.status ===
                    LIEN_WAIVER_STATUS.MARKED_AS_SIGNED
                  }
                  vendor={
                    billLienWaiver.printedName ??
                    billLienWaiver.vendor.displayName
                  }
                  user={billLienWaiver.revisionEvent?.author.fullName}
                  time={billLienWaiver.signedAt}
                />
              )}
              <Flex gap="sm" shrink={false}>
                {isSigned && (
                  <Tooltip message={STRINGS.DELETE_SIGNED_LIEN_WAIVER}>
                    <Button
                      color="neutral"
                      variant="ghost"
                      onClick={onDelete}
                      disabled={isLoading || !canManageLienWaiverRequest}
                    >
                      {isLoading ? <Loader /> : <Icon name="trash" />}
                    </Button>
                  </Tooltip>
                )}

                {!isSigned && onRequestUpdate && (
                  <Tooltip
                    message={
                      isUnrequested
                        ? STRINGS.MARK_AS_REQUESTED_FIRST
                        : STRINGS.UPLOAD_SIGNED_LIEN_WAIVER
                    }
                  >
                    <Button
                      color="neutral"
                      variant="ghost"
                      disabled={isUnrequested || !canManageLienWaiverRequest}
                      onClick={uploadSignatureDialog.show}
                    >
                      <Icon name="signature" />
                    </Button>
                  </Tooltip>
                )}

                <Tooltip
                  message={
                    isSigned
                      ? STRINGS.VIEW_SIGNED_LIEN_WAIVER
                      : STRINGS.VIEW_LIEN_WAIVER
                  }
                >
                  <Button
                    color="neutral"
                    variant="ghost"
                    onClick={previewDrawer.show}
                    disabled={
                      (!billLienWaiver?.fileExport?.document &&
                        !isValidValue) ||
                      isLoading ||
                      !isValidValue ||
                      !canManageLienWaiverRequest
                    }
                  >
                    <Icon name="eye" />
                  </Button>
                </Tooltip>
                {onRequestUpdate && (
                  <Tooltip
                    message={
                      isSigned
                        ? STRINGS.DOWNLOAD_SIGNED_LIEN_WAIVER
                        : STRINGS.DOWNLOAD_LIEN_WAIVER
                    }
                  >
                    <Button
                      color="neutral"
                      variant="ghost"
                      {...(isUnrequested
                        ? {
                            as: "button",
                            disabled:
                              isLoadingLienWaiverPDF ||
                              !isValidValue ||
                              !canManageLienWaiverRequest,
                            onClick: onDownloadRenderedPdf,
                          }
                        : {
                            as: "a",
                            target: "_blank",
                            download: true,
                            href: previewPDF!,
                          })}
                    >
                      {isLoadingLienWaiverPDF ? (
                        <Loader />
                      ) : (
                        <Icon name="download" />
                      )}
                    </Button>
                  </Tooltip>
                )}
              </Flex>
            </Flex>
            {isValidValue &&
              isUnrequested &&
              !isLoading &&
              haveMissingFields &&
              templateData?.missingFields?.length && (
                <Flex align="baseline" gap="sm">
                  <Icon
                    name="exclamation-triangle"
                    color="warning-200"
                    size="sm"
                    variant="light"
                  />
                  <Flex direction="column" gap="sm">
                    <Text
                      as="strong"
                      weight="bold"
                      size="sm"
                      color="warning-200"
                    >
                      {STRINGS.MISSING_FIELDS_TITLE}
                    </Text>
                    <Flex margin={["-4px", "none", "none", "none"]}>
                      <Text size="sm" color="warning-200">
                        {templateData?.missingFields?.join(", ")}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              )}
          </Flex>
        )}
      </Card>
      {previewDrawer.isRendered &&
        !!(value || previewPDF) &&
        canManageLienWaiverRequest && (
          <BillLienWaiverPreviewDrawer
            show={previewDrawer.isVisible}
            onClose={previewDrawer.hide}
            value={value}
            paymentAmount={paymentAmount}
            billId={billId}
            billPaymentId={billPaymentId}
            pdf={isLinkedStatus ? previewPDF! : undefined}
          />
        )}
      {requestDialog.isRendered &&
        canManageLienWaiverRequest &&
        onRequestUpdate && (
          <BillLienWaiverRequestDialog
            show={requestDialog.isVisible}
            onClose={requestDialog.hide}
            recipientEmail={recipientEmail}
            vendor={vendor}
            lienWaiverTemplateId={value}
            paymentAmount={paymentAmount}
            billId={billId}
            billPaymentId={billPaymentId}
            onRequestUpdate={onRequestUpdate}
          />
        )}
      {uploadSignatureDialog.isRendered &&
        billLienWaiver &&
        canManageLienWaiverRequest &&
        onRequestUpdate && (
          <BillLienWaiverMarkSignedDialog
            onClose={uploadSignatureDialog.hide}
            show={uploadSignatureDialog.isVisible}
            billLienWaiver={billLienWaiver}
            onRequestUpdate={onRequestUpdate}
          />
        )}
    </Flex>
  );
};
